/* PhoneDisplay.css */
@keyframes gradientAnimation {
    0% {
      background-position: 0% 0%;
    }
    50% {
      background-position: 100% 0%;
    }
    100% {
      background-position: 0% 0%;
    }
  }
  
  .phone-display {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(135deg, #3A6EA5, #f5f5f5, #3A6EA5);
    background-size: 200% 200%;
    padding-top: 23px;
    padding-bottom: 23px;
    text-align: center;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    z-index: 800;
    flex-direction: column;
    align-items: center;
    animation: gradientAnimation 10s ease infinite;
  }
  
  .phone-number {
    font-size: 32px;
    font-weight: bold;
    color: #3A6EA5;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .phone-number:hover {
    color: #ffffff;
  }
  