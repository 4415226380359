@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:wght@400;700&display=swap');

body {
  font-family: 'Crimson Text', serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #fdf8f2; /* Une couleur douce et accueillante */
  color: #333;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Crimson Text', serif;
  margin: 0;
  padding: 0.5rem;
}

p, a, button, input, textarea {
  font-family: 'Crimson Text', serif;
  margin: 0;
  padding: 0.5rem;
}
