footer {
  font-family: 'Arial, sans-serif';
}

h3 {
  margin-bottom: 10px;
}

p {
  margin: 5px 0;
}


